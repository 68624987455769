<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div >

          <div class="widjetcontent" v-if="timeTableRecord.tables.length > 0">
            <div class="restable" style="text-align: center;">
              <div class="resrow resheader">
                <div class="cell" style="text-align: center;">Time Periods</div>
                <div class="cell" style="text-align: center;" :key="cIndex" v-for="(tc, cIndex) in timeTableRecord.tables">
                  <span>{{ cIndex + 1 }}</span>
                    <br /> {{ tc.from }} - {{ tc.to }}
                  <!-- <span >
                    {{ tc.from }} - {{ tc.to }}<br />{{ cIndex + 1 }}
                  </span> -->
                </div>

              </div>

              <div class="resrow">
                <div class="cell" data-title="Day">
                  Monday
                </div>
                <div class="cell" data-title="Monday" :key="mIndex" v-for="(m, mIndex) in timeTableRecord.tables">
                  <span>{{ m['mon'] ? subjectGroup[ m['mon'] ] : '' }}</span>
                 
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day">
                  Tuesday
                </div>
                <div class="cell" data-title="Tuesday" :key="tIndex" v-for="(t, tIndex) in timeTableRecord.tables">
                  <span >{{ t['tue'] ? subjectGroup[ t['tue'] ] : '' }}</span>
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day">
                  Wednesday
                </div>
                <div class="cell" data-title="Wednesday" :key="wIndex" v-for="(w, wIndex) in timeTableRecord.tables">
                  <span >{{ w['wed'] ? subjectGroup[ w['wed'] ] : ''}}</span>
                  
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day">
                  Thursday
                </div>
                <div class="cell" data-title="Thursday" :key="thIndex" v-for="(th, thIndex) in timeTableRecord.tables">
                  <span >{{ th['thur'] ? subjectGroup[ th['thur'] ] : '' }}</span>
                  
                </div>
              </div>

              <div class="resrow">
                <div class="cell" data-title="Day">
                  Friday
                </div>
                <div class="cell" data-title="Friday" :key="friIndex" v-for="(f, friIndex) in timeTableRecord.tables">
                  <span >{{ f['fri'] ? subjectGroup[ f['fri'] ] : ''}}</span>
                 
                </div>
              </div>

              <div class="resrow" v-if="timeTableRecord.days == 'Mon-Sat' || timeTableRecord.days == 'Mon-Sun'">
                <div class="cell" data-title="Day">
                  Saturday
                </div>
                <div class="cell" data-title="Saturday" :key="satIndex" v-for="(s, satIndex) in timeTableRecord.tables">
                  <span >{{ s['sat'] ? subjectGroup[ s['sat'] ] : ''}}</span>
                
                </div>
              </div>

              <div class="resrow" v-if="timeTableRecord.days == 'Mon-Sun'">
                <div class="cell" data-title="Day">
                  Sunday
                </div>
                <div class="cell" data-title="Sunday" :key="sunIndex" v-for="(su, sunIndex) in timeTableRecord.tables">
                  <span>{{ su['sun'] ? subjectGroup[ su['sun'] ] : ''}}</span>
               
                </div>
              </div>
            </div>
          </div>
          <div  v-else style="box-shadow: 0 0px 0px 0 rgba(0,0,4,0.1);">
            <div style="text-align: center;">
           Timetable Not create...
          </div>
        </div>
         
        </div>
      </div>
    
    </div>

    <vi-spinner
      v-if="loader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    />
  </div>
</template>

<script>
import "vue-select/dist/vue-select.css";
import ViService from "@/services/ViService";
import secureUI from "../../../utils/secureUI";

export default {
  name: "teacher",
  components: {
    'vi-spinner' : () => import('../../Common/ViSpinner')
  },
  data() {
    return {
      userDetail: {},
      loader: false,
      subjects: [],
      timeTableRecord: {
        days: "",
        class: "",
        classroom: "",
        tables: []
      },
      days: [
        {
          label: "Monday - Friday",
          value: "Mon-Fri",
        },
        {
          label: "Monday - Saturday",
          value: "Mon-Sat",
        },
        {
          label: "Monday - Sunday",
          value: "Mon-Sun"
        }
      ],
      otherSubjects: [
        { _id: "Free Period", subjectName: "Free Period" },
        { _id: "Lunch", subjectName: "Lunch" },
        { _id: "Break", subjectName: "Break" }
      ],
      periodsTime: [
        {
          label: "30 Mins",
          value: 30,
        },
        {
          label: "35 Mins",
          value: 35,
        },
        {
          label: "40 Mins",
          value: 40,
        },
        {
          label: "45 Mins",
          value: 45,
        },
        {
          label: "50 Mins",
          value: 50,
        },
        {
          label: "55 Mins",
          value: 55,
        },
        {
          label: "1 Hours",
          value: 60
        }
      ],
    };
  },
  props: {
    classId: {
      type: String,
    },
    classRoom: {
      type: String,
    },
    terms: {
      type: Array,
    },
    accountId: {
      type: String,
    },
    academicYearId: {
      type: String,
    }
  },
 
  created() {
    this.getTimeTableInfo();
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    subjectGroup() {
      return this.subjects.reduce((acc, a) => {
        acc[a._id] = a.subjectName;
        return acc;
      }, {});
    }
  },
  methods: {
   
    async getTimeTableInfo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        let requestBody = {
          class: this.classId,
          room: this.classRoom,
          academicYear: this.academicYearId,
          accountId: this.accountId
        };

        const response = await ViService.viXPost(
          "/academic/getStudentView/time_table",
          requestBody,
          userData.token
        );

       

        if (response.isSuccess && response.data) {
          const resultData = secureUI.secureGet(response.data);
          if( resultData.timeTable && resultData.timeTable._id ) {
            this.timeTableRecord = resultData.timeTable;
          }

          this.subjects = [ ...resultData.subjects, ...this.otherSubjects ];
        } else {
          this.$toasted.error(response.message);
        }
      }
    }
  },
};
</script>

<style scoped>
.link-color {
  color:dodgerblue;
}
</style>
